@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"



























































































































































































































































































































// It's important, that everything ist nested under .print-template, because these styles are
// loaded in ONE stylesheet with all the other styles and will have side effects.
// This happens only when compiling, not in development mode.
.print-template
  position: relative
  margin: 10px
  padding: 0
  width: 630px
  height: 950px
  text-align: left
  background: #ffffff

  table
    border-collapse: collapse
    table-layout: auto
    margin: 0
    padding: 0

  tr,
  td
    margin: 0
    padding: 0
    vertical-align: top
    font-family: "tketyperegular", Arial, sans-serif

  #header
    position: relative
    left: 0
    top: 0
    height: 110px
    margin-bottom: 80px
    background-color: $primary

    .logo
      position: absolute
      display: block
      top: 15px
      left: 20px
      width: 120px
      height: 80px
      color: $primary-invert
      text-indent: -9999em
      overflow: hidden
      background: url(/assets/logos/tke_logo_desktop.svg) no-repeat

    .claim
      position: absolute
      display: block
      bottom: 14px
      left: 170px
      width: 300px
      height: 18px
      color: $primary-invert
      text-indent: -9999rem
      overflow: hidden
      background: url(/assets/logos/tke_claim_desktop.svg) no-repeat

  #main
    position: absolute
    left: 0
    top: 190px
    height: 680px

  #footer
    position: absolute
    left: 0
    top: 870px
    height: 80px

    h1
      position: absolute
      top: 20px
      left: 0
      font: 16px/16px "tketypemedium", Arial, sans-serif
      font-weight: normal

  #logo
    position: absolute
    top: 0
    right: 0

  #logo-next-level
    position: absolute
    top: 60px
    left: 0

  #main

    .table-wrapper
      padding: 4px 0

      &.border
        border-bottom: 2px solid #cccccc

    table
      width: 630px

      td
        padding: 0 0 0 8px

        span

          &.unit
            padding-left: 4px
            font: 10px/10px "tketyperegular", Arial, sans-serif
            color: #c9cbc7
            text-transform: uppercase

        &.border
          border-right: 2px solid #c9cbc7

        &.label
          padding-top: 8px
          font: 10px/10px "tketyperegular", Arial, sans-serif
          color: #c9cbc7
          text-transform: uppercase

        &.unit
          padding-top: 4px
          padding-bottom: 8px
          padding-left: 4px
          font: 10px/10px "tketyperegular", Arial, sans-serif
          color: #c9cbc7
          text-transform: uppercase
          vertical-align: bottom

        &.value
          padding-top: 4px
          padding-bottom: 8px
          font: 32px/32px "tketyperegular", Arial, sans-serif
          color: #004B7C
          vertical-align: bottom

    #main1

      td
        padding-top: 8px
        padding-bottom: 2px
        font: 12px/12px "tketypemedium", Arial, sans-serif
        color: #004B7C

      #str-date
        width: 80px

    #main2

      .col1
        width: 130px

      .col2
        width: 130px

      .col3
        width: 130px

      .col4

        span

          &.unit
            display: inline-block
            zoom: 1
            width: 124px

    #main3

      td
        background-color: #eeeeee

        &.value
          font-size: 40px
          line-height: 40px

        &.col1
          width: 300px

        &.col2
          width: 150px

        &.col3
          width: 20px

        &.col4
          width: 130px

      tr

        &.row1

          td
            height: 50px

            &.col1
              vertical-align: middle
              text-align: center

        &.row2

          td

            &.col3
              vertical-align: bottom
              padding-bottom: 10px

        &.row4

          td
            height: 20px

        &.row7

          td
            height: 50px

    #main5

      td

        &.value
          padding-bottom: 4px
          font-size: 16px
          line-height: 16px
          vertical-align: top

        &.col1
          width: 220px

    #main6
      border: none

      td

        &.label
          padding-bottom: 5px

        &.col1
          width: 7px

        &.col2
          padding-right: 20px

        &.text
          padding-top: 5px
          font: 12px/14px "tketyperegular", Arial, sans-serif
          color: #004B7C
          vertical-align: top

  #address
    position: absolute
    left: 0
    bottom: 0
    width: 630px

    td
      padding: 3px 0 0 0
      font: 12px/12px "tketyperegular", Arial, sans-serif

      &.col1
        width: 105px

      &.col2
        width: 210px

      &.col3
        width: 105px
